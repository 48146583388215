import React, { createContext, useState, useContext } from "react";
import ReactDOM from "react-dom/client";
import Dashboard from "./Dashboard.jsx";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import LoginPage from "./containers/LoginPage.jsx";
import RegisterPage from "./containers/RegisterPage.jsx";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import OrdersPage from "./containers/Orders.jsx";
import { checkAuth, getUser } from "./features/user.js";
import StorageLocations from "./containers/StorageLocations.jsx";
import Vendors from "./containers/Vendors.jsx";
import UsersPage from "./containers/Users.jsx";
import StorageRequestsPage from "./containers/StorageRequests.jsx";
import { GlobalContext } from "./GlobalContext.js";

const App = () => {
  const dispatch = useDispatch();
  const { globalVariable, setGlobalVariable } = useContext(GlobalContext);

  useEffect(() => {
    console.log("checking auth");

    console.log(dispatch(checkAuth()));

    handleFetchData();
  }, []);

  const handleFetchData = async () => {
    const resultAction = await dispatch(getUser());

    setGlobalVariable((prev) => ({ ...prev, user: resultAction.payload }));
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/customers" element={<></>} />
        <Route path="/vendor" element={<Vendors />} />
        <Route path="/storagelocations" element={<StorageLocations />} />
        <Route path="/orders" element={<OrdersPage />} />
        <Route path="/storagerequests" element={<StorageRequestsPage />} />

        <Route path="/users" element={<UsersPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
