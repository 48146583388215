import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import LoginPage from "./containers/LoginPage.jsx";
import RegisterPage from "./containers/RegisterPage.jsx";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { checkAuth } from "./features/user.js";
import { GlobalProvider } from "./GlobalContext.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //  <React.StrictMode>
  <GlobalProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </GlobalProvider>
  //  </React.StrictMode>
);
