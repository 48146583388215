import { React } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { resetRegistered } from "../features/user";
import { useState, componentDidMount, componentDidUpdate } from "react";
import {
  Modal,
  Button,
  Form,
  ProgressBar,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import FieldGroup from "./FieldGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InterchangeRailroadForm from "./InterchangeRailroadForm";

export const StorageViewModal = ({
  show,
  onClose,
  formData,
  UpdateFormData,
  handleSubmit,
}) => {
  const { register, hs, errors } = useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [vendorOptions, setVendorOptions] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    fetch("/api/storage/vend_list")
      .then((response) => response.json())
      .then((json) => {
        console.log("Setting vendor options");
        console.log(json);
        console.log(json.code);
        if (!(json.code === "token_not_valid")) setVendorOptions(json);
      });
  }, []);

  const handleNext = () => {
    //Add Error Handling here

    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const [laterror, setLatError] = useState("");
  const [longerror, setLongError] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    console.log("ID: " + id + " Value: " + value);

    UpdateFormData({
      [id]: value,
    });
  };

  const handleNamedChange = (id, value) => {
    UpdateFormData({
      [id]: value,
    });
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("text");
    const { id } = e.target;

    // Validate pasted data
    if (id === "latitude") {
      const latitude = parseFloat(pastedData);
      if (isNaN(latitude) || latitude < -90 || latitude > 90) {
        setLatError(
          "Invalid latitude value. Latitude must be between -90 and 90."
        );
        e.preventDefault(); // Prevent invalid data from being pasted
      } else {
        setLatError("");
      }
    }

    if (id === "longitude") {
      const latitude = parseFloat(pastedData);
      if (isNaN(latitude) || latitude < -90 || latitude > 90) {
        setLongError(
          "Invalid longitude value.  Longitude must be between -90 and 90."
        );
        e.preventDefault(); // Prevent invalid data from being pasted
      } else {
        setLongError("");
      }
    }
  };

  const usStates = [
    "",
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  return (
    <Modal show={show} onHide={onClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Storage Locations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="progress-wrapper">
          <div className="wiznav">
            <span
              key="step-1"
              className={"wizdot " + (currentPage == 1 ? "wizactive" : "")}
            >
              &bull;
            </span>
            <span
              key="step-2"
              className={"wizdot " + (currentPage == 2 ? "wizactive" : "")}
            >
              &bull;
            </span>
            <span
              key="step-3"
              className={"wizdot " + (currentPage == 3 ? "wizactive" : "")}
            >
              &bull;
            </span>
          </div>
        </div>

        <Form>
          {currentPage === 1 && (
            <>
              <div className="container">
                {" "}
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="vendor_name" className="form-label">
                      Vendor
                    </label>

                    <select
                      id="vendor"
                      value={formData.vendor}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option value="">Select an option</option>

                      {vendorOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.business_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <FieldGroup label="Storage Facility">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="facility_name" className="form-label">
                        Facility Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="facility_name"
                        value={formData.facility_name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label htmlFor="mark" className="form-label">
                        Mark
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mark"
                        value={formData.mark}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        value={formData.country}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="state" className="form-label">
                        State
                      </label>
                      <select
                        className="form-select"
                        id="state"
                        value={formData.state}
                        onChange={handleChange}
                      >
                        {usStates.map((state) => (
                          <option key={state}>{state}</option>
                        ))}
                      </select>{" "}
                    </div>{" "}
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label htmlFor="stationName" className="form-label">
                        Station Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="stationName"
                        value={formData.stationName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="region" className="form-label">
                        Region
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="region"
                        value={formData.region}
                        onChange={handleChange}
                      />
                    </div>{" "}
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="address" className="form-label">
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label htmlFor="zipCode" className="form-label">
                        Zip Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="zipCode"
                        value={formData.zipCode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label htmlFor="splc" className="form-label">
                        SPLC
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="splc"
                        value={formData.splc}
                        onChange={handleChange}
                      />
                    </div>{" "}
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="latitude" className="form-label">
                        Latitude
                      </label>
                      <input
                        type="number"
                        step="any"
                        className={`form-control ${
                          laterror ? "is-invalid" : ""
                        }`}
                        id="latitude"
                        value={formData.latitude}
                        onChange={handleChange}
                        onPaste={handlePaste}
                      />
                      {laterror && (
                        <div className="invalid-feedback">{laterror}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="longitude" className="form-label">
                        Longitude
                      </label>
                      <input
                        type="number"
                        step="any"
                        className={`form-control ${
                          longerror ? "is-invalid" : ""
                        }`}
                        id="longitude"
                        value={formData.longitude}
                        onChange={handleChange}
                        onPaste={handlePaste}
                      />
                      {longerror && (
                        <div className="invalid-feedback">{longerror}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="primaryContact" className="form-label">
                        Primary Contact
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="primaryContact"
                        value={formData.primaryContact}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="primaryEmail" className="form-label">
                        Primary Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="primaryEmail"
                        value={formData.primaryEmail}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="secondaryContact" className="form-label">
                        Secondary Contact
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="secondaryContact"
                        value={formData.secondaryContact}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <label htmlFor="secondaryEmail" className="form-label">
                        Secondary Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="secondaryEmail"
                        value={formData.secondaryEmail}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label htmlFor="effectiveDate" className="form-label">
                        Effective Date
                      </label>
                      <div>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          className="form-control"
                          id="effectiveDate"
                          name="effectiveDate"
                          selected={formData.effectiveDate}
                          onChange={(date) =>
                            handleNamedChange("effectiveDate", date)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label htmlFor="expirationDate" className="form-label">
                        Expiration Date
                      </label>
                      <div>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          className="form-control"
                          id="expirationDate"
                          name="expirationDate"
                          selected={formData.expirationDate}
                          onChange={(date) =>
                            handleNamedChange("expirationDate", date)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label htmlFor="capacity" className="form-label">
                        Capacity
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="capacity"
                        value={formData.capacity}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mb-3">
                      <label htmlFor="availableSpace" className="form-label">
                        Available Space
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="availableSpace"
                        value={formData.availableSpace}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="mb-3">
                      <label htmlFor="featuresList" className="form-label">
                        Features List
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="featuresList"
                        value={formData.featuresList}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </FieldGroup>
              </div>
            </>
          )}
          {currentPage === 2 && (
            <>
              <div className="container">
                <FieldGroup label="Interchange">
                  <InterchangeRailroadForm
                    handleChange={handleChange}
                    formData={formData}
                    setFormData={UpdateFormData}
                  />
                </FieldGroup>
              </div>
            </>
          )}
          {currentPage === 3 && (
            <>
              <Container>
                <h4>Rates</h4>
                <Row>
                  <Col md={3}></Col>
                  <Col md={3}>
                    <Form.Label>Daily Rate</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Label>Switch Fee (In)</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Label>Switch Fee (Out)</Form.Label>
                  </Col>
                </Row>

                <b className="mt-4">Cleaned Cars</b>
                <Row>
                  <Col md={3}>
                    <Form.Label>Empty (only)</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.emptyDailyRate}
                      onChange={handleChange}
                      id="emptyDailyRate"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.emptySwitchFeeIn}
                      onChange={handleChange}
                      id="emptySwitchFeeIn"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.emptySwitchFeeOut}
                      onChange={handleChange}
                      id="emptySwitchFeeOut"
                    />
                  </Col>
                </Row>

                <b className="mt-4">Non-Placarded Rates</b>
                <Row>
                  <Col md={3}>
                    <Form.Label>Loaded</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.loadedDailyRate}
                      onChange={handleChange}
                      id="loadedDailyRate"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.loadedSwitchFeeIn}
                      onChange={handleChange}
                      id="loadedSwitchFeeIn"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.loadedSwitchFeeOut}
                      onChange={handleChange}
                      id="loadedSwitchFeeOut"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={3}>
                    <Form.Label>Empty</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.nonPlacardedEmptyDailyRate}
                      onChange={handleChange}
                      id="nonPlacardedEmptyDailyRate"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.nonPlacardedEmptySwitchFeeIn}
                      onChange={handleChange}
                      id="nonPlacardedEmptySwitchFeeIn"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.nonPlacardedEmptySwitchFeeOut}
                      onChange={handleChange}
                      id="nonPlacardedEmptySwitchFeeOut"
                    />
                  </Col>
                </Row>

                <b className="mt-4">Placarded Cars</b>
                <Row>
                  <Col md={3}>
                    <Form.Label>Loaded</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedLoadedDailyRate}
                      onChange={handleChange}
                      id="placardedLoadedDailyRate"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedLoadedSwitchFeeIn}
                      onChange={handleChange}
                      id="placardedLoadedSwitchFeeIn"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedLoadedSwitchFeeOut}
                      onChange={handleChange}
                      id="placardedLoadedSwitchFeeOut"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={3}>
                    <Form.Label>Empty</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedEmptyDailyRate}
                      onChange={handleChange}
                      id="placardedEmptyDailyRate"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedEmptySwitchFeeIn}
                      onChange={handleChange}
                      id="placardedEmptySwitchFeeIn"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedEmptySwitchFeeOut}
                      onChange={handleChange}
                      id="placardedEmptySwitchFeeOut"
                    />
                  </Col>
                </Row>

                <b className="mt-4">Other Rates or Fees</b>
                <Row>
                  <Col md={3}>
                    <Form.Label>Intra-Yard Switch Fee</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.intraYardSwitchFee}
                      onChange={handleChange}
                      id="intraYardSwitchFee"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Label>Cherry Picking Fee</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.cherryPickingFee}
                      onChange={handleChange}
                      id="cherryPickingFee"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Label>Non-Consent Fee</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.nonConsentFee}
                      onChange={handleChange}
                      id="nonConsentFee"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Label>Holdover Fee</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.holdoverFee}
                      onChange={handleChange}
                      id="holdoverFee"
                    />
                  </Col>
                </Row>

                <b className="mt-4">Access Fee (per occurrence)</b>
                <Row>
                  <Col md={6}>
                    <Form.Label>Cleaning or Maintenance</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.cleaningMaintenanceFee}
                      onChange={handleChange}
                      id="cleaningMaintenanceFee"
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Label>Inspections</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.inspectionFee}
                      onChange={handleChange}
                      id="inspectionFee"
                    />
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {currentPage > 1 && (
          <Button variant="secondary" onClick={handlePrevious}>
            Previous
          </Button>
        )}
        {currentPage < 3 && (
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        )}
        {currentPage === 3 && (
          <Button variant="primary" onClick={handleSubmit}>
            {formData.id > 0 ? "Update" : "Add"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
