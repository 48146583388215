import React, { useEffect } from "react";
import HeaderBar from "../HeaderBar";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useState } from "react";
import { register, resetRegistered, login } from "../features/user";
import FooterBar from "../FooterBar";
import "bootstrap-icons/font/bootstrap-icons.css";
import { VendorViewModal } from "./VendorViewModal.jsx";

const Vendors = () => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const openModal = () => {
    setModalData((prevState) => ({
      ...prevState,
      modal: true,
    }));
  };

  const UpdateFormData = (ud) => {
    setModalData({
      ...modalData,
      data: { ...modalData.data, ...ud },
    });
  };

  const closeModal = () => {
    setModalData((prevState) => ({
      ...prevState,
      modal: false,
    }));
  };

  useEffect(() => {
    console.log("Fetching data...");
    fetch("/api/storage/vend_list")
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setData(json);
      });
  }, []);

  const defaultValues = {
    id: "",
    business_name: "",
    description: "",
    website: "",
    expiration_date: undefined,
    effective_date: undefined,
    zipcode: "",
    address: "",
    city: "",
    state: "",
    country: "",
    secondary_email: "",
    primary_email: "",
    primary_contact: "",
  };

  const [modalData, setModalData] = useState({
    modal: false,
    storageid: 0,
    data: defaultValues,
  });

  const [data, setData] = useState({});

  const columns = [
    { field: "id", displayName: "ID" },
    { field: "business_name", displayName: "Vendor Name" },
    { field: "primary_contact", displayName: "Contact" },
    { field: "primary_email", displayName: "Email" },
    { field: "city", displayName: "City" },
    { field: "state", displayName: "State" },
  ];

  const parseJson = (data, str) => {
    const partsArray = str.split(".");

    var result = data;

    partsArray.forEach((item) => {
      result = result[item];
    });

    return result;
  };

  // Function to handle sorting
  const handleSort = (field) => {
    if (field === sortedField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortDirection("asc");
    }
  };

  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const updateItem = (id, keyVal, newValue) => {
    // Find the index of the item to update

    const index = data.findIndex((item) => item.id === id);

    if (index === -1) {
      // Handle item not found
      return;
    }
    console.log(index);
    // Create a new array with the updated item
    const updatedArray = [...data];
    updatedArray[index] = { ...updatedArray[index], [keyVal]: newValue };

    console.log(updatedArray);
    // Set the state variable to the new array
    setData(updatedArray);
  };

  // Function to handle search
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  var filteredData = [];

  if (data !== null && data !== undefined && data.length > 0) {
    filteredData = data.filter((item) =>
      Object.values(item).some((value) => {
        const lowercasedValue = value.toString().toLowerCase();
        const includesSearchTerm = lowercasedValue.includes(
          searchTerm.toLowerCase()
        );

        return includesSearchTerm;
      })
    );
  }
  const sortedData = [...filteredData].sort((a, b) => {
    const fieldA = a[sortedField]?.toLowerCase() || "";
    const fieldB = b[sortedField]?.toLowerCase() || "";
    return sortDirection === "asc"
      ? fieldA.localeCompare(fieldB)
      : fieldB.localeCompare(fieldA);
  });

  // Calculate pagination
  const totalItems = sortedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSubmit = () => {
    const {
      id,
      business_name,
      description,
      website,
      expiration_date,
      effective_date,
      zipcode,
      address,
      city,
      state,
      country,
      secondary_email,
      primary_email,
      primary_contact,
    } = modalData.data;

    const body = JSON.stringify({
      id,
      business_name,
      description,
      website,
      expiration_date,
      effective_date,
      zipcode,
      address,
      city,
      state,
      country,
      secondary_email,
      primary_email,
      primary_contact,
    });

    console.log(body);

    const url =
      modalData.data.id > 0
        ? "/api/storage/vend_update"
        : "api/storage/vend_create";

    const response = fetch(url, {
      method: "POST",

      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },

      body: body,
    });

    updateItem(
      modalData.data.id,
      "business_name",
      modalData.data.business_name
    );

    closeModal();
  };

  const handleRowClick = (itemId) => {
    // Implement logic to handle row click, such as opening a modal or navigating to a new page
    console.log(`Clicked on item with ID: ${itemId}`);

    fetch("api/storage/vend_get/" + itemId, {
      method: "GET",

      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setModalData((prevState) => ({
          ...prevState,
          modal: true,
          data: json,
        }));
      });

    fetch("api/usertype", {
      method: "GET",
      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      });
  };

  const tablecode = (
    <div className="container mt-4">
      <VendorViewModal
        show={modalData.modal}
        onClose={closeModal}
        vendorID={modalData.id}
        formData={modalData.data}
        UpdateFormData={UpdateFormData}
        handleSubmit={handleSubmit}
      />
      <div className="page-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1 style={{ margin: "0" }}>Vendors</h1>
          <button
            className="btn btn-primary mt-4"
            onClick={openModal}
            style={{ marginLeft: "auto" }}
          >
            Add
          </button>
        </div>

        <p className="lead">Manage and view the Vendors</p>
      </div>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            {columns.map((column) => (
              <th key={column.field} onClick={() => handleSort(column.field)}>
                {column.displayName}
                {sortedField === column.field && sortDirection === "asc" && (
                  <i className="bi bi-caret-up-fill"></i>
                )}
                {sortedField === column.field && sortDirection === "desc" && (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={column.field} onClick={() => handleRowClick(item.id)}>
                  {
                    parseJson(item, column.field)
                    //                  item[column.field]
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FaChevronLeft />
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );

  return (
    <div className="home-page-container">
      <div className="home-page-container1">
        <HeaderBar />
        <div>{tablecode}</div>
        <div className="pt-5 mt-5">&nbsp;</div>
        <FooterBar />
      </div>
    </div>
  );
};

export default Vendors;
