import { React } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { resetRegistered } from "../features/user";
import { useState, componentDidMount, componentDidUpdate } from "react";
import { Modal, Button, Form, ProgressBar } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FieldGroup from "./FieldGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const UserViewModal = ({
  show,
  onClose,
  formData,
  UpdateFormData,
  handleSubmit,
}) => {
  const { register, hs, errors } = useForm();

  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChange = (e) => {
    console.log(e.target);
    const { id, value } = e.target;
    UpdateFormData({
      [id]: value,
    });
  };

  useEffect(() => {
    console.log("Users page");
    console.log(formData);
  }, []);

  const handleNamedChange = (id, value) => {
    UpdateFormData({
      [id]: value,
    });
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {});
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const userTypeOptions = [
    { user_type_id: "Storage", user_type: "Storage" },
    { user_type_id: "Vendor", user_type: "Vendor" },
    { user_type_id: "Admin", user_type: "Admin" },
  ];

  return (
    <Modal show={show} onHide={onClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <>
            <div className="container">
              <FieldGroup label="User Details">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="first_name" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="last_name" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="vendor_name" className="form-label">
                      User Type
                    </label>

                    <select
                      value={formData.user_type}
                      onChange={handleChange}
                      id="user_type"
                      className="form-select"
                    >
                      <option value="">Select an option</option>
                      {userTypeOptions.map((option) => (
                        <option
                          key={option.user_type_id}
                          value={option.user_type_id}
                        >
                          {option.user_type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="work_phone" className="form-label">
                      Work Phone
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="work_phone"
                      value={formData.work_phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="company_name" className="form-label">
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="company_name"
                      value={formData.company_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="company_state" className="form-label">
                      Company State
                    </label>
                    <select
                      className="form-select"
                      id="company_state"
                      value={formData.company_state}
                      onChange={handleChange}
                    >
                      {usStates.map((state) => (
                        <option key={state}>{state}</option>
                      ))}
                    </select>{" "}
                  </div>{" "}
                </div>

                {/*
                verified =
                models.BooleanField(default=False) 
                 company_name =
                models.CharField(max_length=255, blank=True) company_state =
                models.CharField(max_length=255, blank=True)   is_active = models.BooleanField(default=True)
                 */}
              </FieldGroup>
            </div>
          </>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
