import React from "react";

import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "./features/user";

function HeaderBar() {
  const { isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const guestLinks = (
    <>
      <li className="nav-item">
        <NavLink className="nav-link" to="/login" role="button">
          Login
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/register" role="button">
          Register
        </NavLink>
      </li>
    </>
  );

  const authLinksStorage = (
    <>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Storage
        </a>
        <ul className="dropdown-menu">
          <li>
            <NavLink className="dropdown-item" to="/login">
              Search
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item" to="/orders">
              Order Requests
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Vendors
        </a>
        <ul className="dropdown-menu">
          <li>
            <NavLink className="dropdown-item" to="/storagelocations">
              Storage Locations
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item" to="/vendor">
              Vendor Details
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item" to="/storagerequests">
              Storage Requests
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Admin
        </a>
        <ul className="dropdown-menu">
          <li>
            <NavLink className="dropdown-item" to="/users">
              Users
            </NavLink>
          </li>
        </ul>
      </li>

      <li className="nav-item">
        <a className="nav-link" href="#!" onClick={() => dispatch(logout())}>
          Logout
        </a>
      </li>
    </>
  );

  const authLinksVendors = (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Vendors
      </a>
      <ul className="dropdown-menu">
        <li>
          <NavLink className="dropdown-item" to="/login">
            Storage Locations
          </NavLink>
        </li>
        <li>
          <NavLink className="dropdown-item" to="/login">
            Vendor Details
          </NavLink>
        </li>
      </ul>
    </li>
  );

  const authLinksAdmin = (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Admin
      </a>
      <ul className="dropdown-menu">
        <li>
          <NavLink className="dropdown-item" to="/login">
            Users
          </NavLink>
        </li>
        <li>
          <NavLink className="dropdown-item" to="/login">
            Vendors
          </NavLink>
        </li>
        <li>
          <NavLink className="dropdown-item" to="/login">
            Customers
          </NavLink>
        </li>
      </ul>
    </li>
  );

  return (
    <div className="home-page-frame">
      <div className="home-page-header">
        <div className="home-page-frame1">
          <img
            alt="image"
            src="/external/tq_14fwkvszgn-kn2n-200h-200h.png"
            className="home-page-image"
          />
          <div className="home-page-frame2">
            <div className="home-page-frame-margin">
              <div className="home-page-heading">
                <span className="home-page-text">
                  <span>eRail Storage</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <nav
          className="navbar navbar-expand-lg bg-body-tertiary nav-color "
          data-bs-theme="dark"
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link" aria-current="page" to="/">
                    Home
                  </NavLink>
                </li>

                {isAuthenticated ? authLinksStorage : guestLinks}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default HeaderBar;
