import React from "react";
import HeaderBar from "../HeaderBar";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import user, { register } from "../features/user";
import FooterBar from "../FooterBar";

const RegisterPage = () => {
  const { registered, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    company_name: "",
    company_state: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    work_phone: "",
    cell_phone: "",
    user_type: "storage",
  });

  if (registered) {
    return <Navigate to="/login" />;
  }

  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(register(formData));
  };

  return (
    <div className="home-page-container">
      <div className="home-page-container1">
        <HeaderBar />
        <div>
          <div>
            <h1>Register for an Account</h1>
          </div>

          <form className="mt-5" onSubmit={onSubmit}>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  onChange={(e) =>
                    setFormData({ ...formData, user_type: "storage" })
                  }
                  checked
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  I Need Storage
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  onChange={(e) =>
                    setFormData({ ...formData, user_type: "vendor" })
                  }
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  I Have Storage
                </label>
              </div>
            </div>
            <div className="form-group mt-3">
              <label className="form-label" htmlFor="first_name">
                First Name
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="first_name"
                value={formData.first_name}
                onChange={(e) =>
                  setFormData({ ...formData, first_name: e.target.value })
                }
              />
            </div>

            <div className="form-group mt-3">
              <label className="form-label" htmlFor="last_name">
                Last Name
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="last_name"
                value={formData.last_name}
                onChange={(e) =>
                  setFormData({ ...formData, last_name: e.target.value })
                }
              />
            </div>

            <div className="form-group mt-3">
              <label className="form-label" htmlFor="company_state">
                State
              </label>
              <select
                className="form-select"
                id="company_state"
                name="company_state"
                onChange={(e) =>
                  setFormData({ ...formData, company_state: e.target.value })
                }
              >
                {usStates.map((state) => (
                  <option key={state}>{state}</option>
                ))}
              </select>{" "}
            </div>

            <div className="form-group mt-3">
              <label className="form-label" htmlFor="company_name">
                Company Name
              </label>
              <input
                className="form-control"
                type="text"
                required
                name="company_name"
                value={formData.company_name}
                onChange={(e) =>
                  setFormData({ ...formData, company_name: e.target.value })
                }
              />
            </div>

            <div className="form-group mt-3">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>
            <div className="form-group mt-3">
              <label className="form-label" htmlFor="work_phone">
                Work Phone
              </label>
              <input
                className="form-control"
                type="text"
                name="work_phone"
                value={formData.work_phone}
                onChange={(e) =>
                  setFormData({ ...formData, work_phone: e.target.value })
                }
              />
            </div>

            <div className="form-group mt-3">
              <label className="form-label" htmlFor="cell_phone">
                Cell Phone
              </label>
              <input
                className="form-control"
                type="text"
                name="cell_phone"
                value={formData.cell_phone}
                onChange={(e) =>
                  setFormData({ ...formData, cell_phone: e.target.value })
                }
              />
            </div>

            <div className="form-group mt-3">
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <input
                className="form-control"
                type="password"
                name="password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
            </div>
            {loading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <button className="btn btn-primary mt-4" type="submit">
                Register
              </button>
            )}
          </form>
        </div>
        <div className="pt-5 mt-5">&nbsp;</div>
        <FooterBar />
      </div>
    </div>
  );
};

export default RegisterPage;
