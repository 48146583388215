import React, { useEffect } from "react";
import HeaderBar from "../HeaderBar";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useState } from "react";
import { register, resetRegistered, login } from "../features/user";
import FooterBar from "../FooterBar";
import "bootstrap-icons/font/bootstrap-icons.css";
import { StorageViewModal } from "./StorageViewModal.jsx";

const StorageLocations = () => {
  const dispatch = useDispatch();

  const defaultValues = {
    id: "",
    vendor: "",
    vendor_name: "",

    capacity: 0,
    primaryEmail: "",
    primaryContact: "",
    longitude: 0,
    latitude: 0,
    splc: "",
    address: "",
    region: "",
    state: "",
    mark: "",
    facility_name: "",
    country: "United States",
    stationName: "",
    zipCode: "",
    secondaryContact: "",
    secondaryEmail: "",
    effectiveDate: "",
    expirationDate: "",
    availableSpace: 0,
    featuresList: "",
    emptyDailyRate: "",
    emptySwitchFeeIn: "",
    emptySwitchFeeOut: "",
    loadedDailyRate: "",
    loadedSwitchFeeIn: "",
    loadedSwitchFeeOut: "",
    nonPlacardedEmptyDailyRate: "",
    nonPlacardedEmptySwitchFeeIn: "",
    nonPlacardedEmptySwitchFeeOut: "",
    placardedLoadedDailyRate: "",
    placardedLoadedSwitchFeeIn: "",
    placardedLoadedSwitchFeeOut: "",
    placardedEmptyDailyRate: "",
    placardedEmptySwitchFeeIn: "",
    placardedEmptySwitchFeeOut: "",
    intraYardSwitchFee: "",
    cherryPickingFee: "",
    nonConsentFee: "",
    holdoverFee: "",
    cleaningMaintenanceFee: "",
    inspectionFee: "",
    eItems: [],
  };

  const [data, setData] = useState({});
  const [modalData, setModalData] = useState({
    modal: false,
    storageid: 0,
    data: defaultValues,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const UpdateFormData = (ud) => {
    setModalData({
      ...modalData,
      data: { ...modalData.data, ...ud },
    });
  };

  const openModal = () => {
    setModalData((prevState) => ({
      ...prevState,
      modal: true,
    }));
  };

  const openModalAdd = () => {
    setModalData((prevState) => ({
      ...prevState,
      modal: true,
    }));
  };

  const closeModal = () => {
    setModalData((prevState) => ({
      ...prevState,
      modal: false,
    }));
  };

  useEffect(() => {
    console.log("Fetching data...");
    fetch("/api/storage/stor_list")
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setData(json);
      });
  }, []);

  const columns = [
    { field: "id", displayName: "ID" },
    { field: "facility_name", displayName: "Facility Name" },
    { field: "mark", displayName: "Mark" },
    { field: "station_name", displayName: "Station Name" },
    { field: "email", displayName: "Email" },
    { field: "region", displayName: "Region" },
  ];

  const updateItem = (id, keyVal, newValue) => {
    // Find the index of the item to update
    const index = data.findIndex((item) => item.id === id);

    if (index === -1) {
      // Handle item not found
      return;
    }

    // Create a new array with the updated item
    const updatedArray = [...data];
    updatedArray[index] = { ...updatedArray[index], [keyVal]: newValue };

    // Set the state variable to the new array
    setData(updatedArray);
  };

  const parseJson = (data, str) => {
    const partsArray = str.split(".");

    var result = data;

    partsArray.forEach((item) => {
      result = result[item];
    });

    return result;
  };

  // Function to handle sorting
  const handleSort = (field) => {
    if (field === sortedField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortDirection("asc");
    }
  };

  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to handle search
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  var filteredData = [];

  if (data !== null && data !== undefined && data.length > 0) {
    filteredData = data.filter((item) =>
      Object.values(item).some((value) => {
        const lowercasedValue = value.toString().toLowerCase();
        const includesSearchTerm = lowercasedValue.includes(
          searchTerm.toLowerCase()
        );

        return includesSearchTerm;
      })
    );
  }
  const sortedData = [...filteredData].sort((a, b) => {
    const fieldA = a[sortedField]?.toLowerCase() || "";
    const fieldB = b[sortedField]?.toLowerCase() || "";
    return sortDirection === "asc"
      ? fieldA.localeCompare(fieldB)
      : fieldB.localeCompare(fieldA);
  });

  // Calculate pagination
  const totalItems = sortedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSubmit = () => {
    console.log("submitting data");

    console.log(modalData.data);

    const {
      id,
      vendor,
      reservation_rate,
      cherry_picking_rate,
      loaded_switch_out,
      loaded_switch_in,
      hazmat_switch_out,
      hazmat_switch_in,
      special_switching_rate,
      switching_rate,
      switch_out,
      switch_in,
      baseDaily_rate,
      location1_description,
      location1_fasc,
      location1_r260,
      location1_longitude,
      location1_latitude,
      location1_splc,
      location1_station_name,
      location_state,
      location1_country,
      interchange_unit,
      interchange_gross_railroad_capacity,
      interchange_mark,
      interchange_railroad_name,
      availableSpace,
      capacity,
      features_list,
      expiration_date,
      effective_date,
      secondary_email,
      secondary_contact,
      rateEffective_date,
      rateExpiration_date,
      primaryEmail,
      primaryContact,
      longitude,
      latitude,
      splc,
      zipcode,
      address,
      region,
      station_name,
      state,
      mark,
      facility_name,
      country,
    } = modalData.data;

    const body = JSON.stringify({
      id,
      vendor,
      reservation_rate,
      cherry_picking_rate,
      loaded_switch_out,
      loaded_switch_in,
      hazmat_switch_out,
      hazmat_switch_in,
      special_switching_rate,
      switching_rate,
      switch_out,
      switch_in,
      baseDaily_rate,
      location1_description,
      location1_fasc,
      location1_r260,
      location1_longitude,
      location1_latitude,
      location1_splc,
      location1_station_name,
      location_state,
      location1_country,
      interchange_unit,
      interchange_gross_railroad_capacity,
      interchange_mark,
      interchange_railroad_name,
      available_space: availableSpace,
      capacity,
      features_list,
      expiration_date,
      effective_date,
      secondary_email,
      secondary_contact,
      rateEffective_date,
      rateExpiration_date,
      primaryEmail,
      primaryContact,
      longitude,
      latitude,
      splc,
      zipcode,
      address,
      region,
      station_name,
      state,
      mark,
      facility_name,
      country,
      rawData: modalData.data,
    });

    const url =
      modalData.data.id > 0
        ? "/api/storage/stor_update"
        : "api/storage/stor_create";

    const response = fetch(url, {
      method: "POST",

      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },

      body: body,
    });

    updateItem(
      modalData.data.id,
      "facility_name",
      modalData.data.facility_name
    );

    closeModal();
  };

  const handleRowClick = (itemId) => {
    // Implement logic to handle row click, such as opening a modal or navigating to a new page
    console.log(`Clicked on item with ID: ${itemId}`);

    fetch("api/storage/stor_get/" + itemId, {
      method: "GET",

      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        console.log(json.rawData.eItems);
        setModalData((prevState) => ({
          ...prevState,
          modal: true,
          data: json,
          data: { eItems: json.rawData.eItems },
        }));
      });

    fetch("api/usertype", {
      method: "GET",

      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      });
  };

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   console.log("Storage call");

  //   const { facility_name, mark } = formData;

  //   const body = JSON.stringify({ facility_name, mark });

  //   const response = fetch("api/storage/create", {
  //     method: "POST",

  //     headers: {
  //       Accept: "application/json",

  //       "Content-Type": "application/json",
  //     },

  //     body: body,
  //   });
  // };
  // const onSubmitU = (e) => {
  //   e.preventDefault();
  //   console.log("Storage call U");

  //   const { facility_name, mark, latitude, longitude, id } = formData;

  //   const body = JSON.stringify({
  //     facility_name,
  //     mark,
  //     latitude,
  //     longitude,
  //     id,
  //   });

  //   const response = fetch("api/storage/update", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },

  //     body: body,
  //   });
  // };

  const tablecode = (
    <div className="container mt-4">
      <StorageViewModal
        show={modalData.modal}
        onClose={closeModal}
        storageID={modalData.id}
        formData={modalData.data}
        UpdateFormData={UpdateFormData}
        handleSubmit={handleSubmit}
      />
      <div className="page-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1 style={{ margin: "0" }}>Storage Locations</h1>
          <button
            className="btn btn-primary mt-4"
            onClick={openModalAdd}
            style={{ marginLeft: "auto" }}
          >
            Add
          </button>
        </div>

        <p className="lead">Manage and view your storage locations</p>
      </div>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            {columns.map((column) => (
              <th key={column.field} onClick={() => handleSort(column.field)}>
                {column.displayName}
                {sortedField === column.field && sortDirection === "asc" && (
                  <i className="bi bi-caret-up-fill"></i>
                )}
                {sortedField === column.field && sortDirection === "desc" && (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={column.field} onClick={() => handleRowClick(item.id)}>
                  {
                    parseJson(item, column.field)
                    //                  item[column.field]
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FaChevronLeft />
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );

  return (
    <div className="home-page-container">
      <div className="home-page-container1">
        <HeaderBar />
        <div>{tablecode}</div>
        <div className="pt-5 mt-5">&nbsp;</div>
        <FooterBar />
      </div>
    </div>
  );
};

export default StorageLocations;
