import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user";
import storageReducer from "./features/storage";

const store = configureStore({
  reducer: {
    user: userReducer,
    storage: storageReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
