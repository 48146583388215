import React from "react";

function FooterBar() {
  return (
    <div className="home-page-footer">
      <div className="home-page-frame8">
        <div className="home-page-text20">
          <span className="home-page-text21">
            <span>eRail Storage © 2024</span>
          </span>
        </div>
      </div>
      <div className="home-page-navigation2">
        <div className="home-page-link3">
          <span className="home-page-text23">
            <span>Privacy Policy</span>
          </span>
        </div>
        <div className="home-page-link4">
          <span className="home-page-text25">
            <span>Terms of Use</span>
          </span>
        </div>
        <div className="home-page-link5">
          <span className="home-page-text27">
            <span>Contact Us</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default FooterBar;
