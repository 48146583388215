import React, { useEffect } from "react";
import HeaderBar from "../HeaderBar";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useState } from "react";
import { register, resetRegistered, login } from "../features/user";
import FooterBar from "../FooterBar";
import "bootstrap-icons/font/bootstrap-icons.css";
import { OrderViewModal } from "./OrderViewModal.jsx";
import { IoMdReturnLeft } from "react-icons/io";

const OrdersPage = () => {
  const dispatch = useDispatch();

  const defaultValues = {
    id: "",

    storage: "",
    anticipated_start_date: undefined,
    anticipated_end_date: undefined,
    loaded_empty: "Loaded",
    car_type: "Equipped Box Cars",
    commodity: "",
    expected_no: 0,
    hazmat: "No",
    rail_car_id: "",
    ssds_doc: undefined,
    feature_hazmat: false,
    feature_comment_hazmat: "",
    feature_loaded_cars: false,
    feature_comment_loaded_cars: "",
    feature_cherry_picking: false,
    feature_comment_cherry_picking: "",
    feature_secured_facility: false,
    feature_comment_secured_facility: "",
    feature_repair: false,
    feature_comment_repair: "",
    feature_mechanical: false,
    feature_comment_mechanical: "",
    feature_cleaning: false,
    feature_comment_cleaning: "",
    feature_recertification: false,
    feature_comment_recertification: "",
    feature_stripe_aligning: false,
    feature_comment_stripe_aligning: "",
    add_reserved_spaces: false,
    reserved_spaces: 0,
    reserved_effective_date: undefined,
    reserved_expiration_date: undefined,
    country: "United States",
    storName: "",
    storID: 0,
  };
  const [modalData, setModalData] = useState({
    modal: false,
    storageid: 0,
    data: defaultValues,
  });

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const UpdateFormData = (ud) => {
    setModalData({
      ...modalData,
      data: { ...modalData.data, ...ud },
    });
  };

  const openModal = () => {
    setModalData((prevState) => ({
      ...prevState,
      modal: true,
    }));
  };

  const closeModal = () => {
    setModalData((prevState) => ({
      ...prevState,
      modal: false,
    }));
  };

  useEffect(() => {
    console.log("Fetching data...");
    fetch("/api/order/list")
      .then((response) => response.json())
      .then((json) => {
        console.log("Order list:");
        console.log(json);
        setData(json);
        /*
        setData([
          {
            status: "Accepted",
            id: 1,
            storage: {
              facility_name: "Union Pacific Intermodal",
              station_name: "North Yard",
            },
            zip: "12345",
            email: "contact@upintermodal.com",
            region: "North",
          },
          {
            status: "Waiting Response",
            id: 2,
            storage: {
              facility_name: "CSX Transportation Terminal",
              station_name: "South Junction",
            },
            zip: "67890",
            email: "contact@csxterminal.com",
            region: "South",
          },
          {
            status: "Needs Response",
            id: 3,
            storage: {
              facility_name: "BNSF Railway Depot",
              station_name: "East End",
            },
            zip: "54321",
            email: "contact@bnsfdepot.com",
            region: "East",
          },
          {
            status: "Expired",
            id: 4,
            storage: {
              facility_name: "Norfolk Southern Rail Yard",
              station_name: "West Point",
            },
            zip: "09876",
            email: "contact@nsrailyard.com",
            region: "West",
          },
          {
            status: "Rejected",
            id: 5,
            storage: {
              facility_name: "Canadian National Terminal",
              station_name: "Central Hub",
            },
            zip: "11223",
            email: "contact@cnterminal.com",
            region: "Central",
          },
        ]);
        */
      });
  }, []);

  const columns = [
    { field: "status", displayName: "Status" },
    { field: "id", displayName: "ID" },

    { field: "created_by.email", displayName: "Requestor" },

    { field: "storage.facility_name", displayName: "Facility Name" },
    { field: "zip", displayName: "Zip" },
    { field: "storage.station_name", displayName: "Station Name" },
    { field: "email", displayName: "Email" },
    { field: "region", displayName: "Region" },
  ];

  const parseJson = (data, str) => {
    const partsArray = str.split(".");

    var result = data;
    // console.log(data);

    partsArray.forEach((item) => {
      if (result != null) result = result[item];
    });

    return result;
  };

  // Function to handle sorting
  const handleSort = (field) => {
    if (field === sortedField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedField(field);
      setSortDirection("asc");
    }
  };

  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Function to handle search
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  var filteredData = [];

  if (data !== null && data !== undefined && data.length > 0) {
    var filteredData = data;

    if (searchTerm.length > 0) {
      filteredData = data.filter((item) =>
        Object.values(item).some((value) => {
          const lowercasedValue = value.toString().toLowerCase();
          const includesSearchTerm = lowercasedValue.includes(
            searchTerm.toLowerCase()
          );

          return includesSearchTerm;
        })
      );
    }
  }

  const sortedData = [...filteredData].sort((a, b) => {
    const fieldA = a[sortedField]?.toLowerCase() || "";
    const fieldB = b[sortedField]?.toLowerCase() || "";
    return sortDirection === "asc"
      ? fieldA.localeCompare(fieldB)
      : fieldB.localeCompare(fieldA);
  });

  // Calculate pagination
  const totalItems = sortedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const handleRowClick = (itemId) => {
    // Implement logic to handle row click, such as opening a modal or navigating to a new page
    console.log(`NEEDS UPDATE:  Clicked on item with ID: ${itemId}`);

    fetch("api/order/get/" + itemId, {
      method: "GET",

      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);

        setModalData((prevState) => ({
          ...prevState,
          modal: true,
          data: {
            ...modalData.data,
            ...json,
            storID: json.storage.id,
            storName: json.storage.facility_name,
            storage: json.storage.id,
          },
        }));
      });

    fetch("api/usertype", {
      method: "GET",

      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      });
  };

  const handleSubmit = () => {
    const {
      id,
      storage,
      anticipated_start_date,
      anticipated_end_date,
      loaded_empty,
      car_type,
      commodity,
      expected_no,
      hazmat,
      rail_car_id,
      ssds_doc,
      feature_hazmat,
      feature_comment_hazmat,
      feature_loaded_cars,
      feature_comment_loaded_cars,
      feature_cherry_picking,
      feature_comment_cherry_picking,
      feature_secured_facility,
      feature_comment_secured_facility,
      feature_repair,
      feature_comment_repair,
      feature_mechanical,
      feature_comment_mechanical,
      feature_cleaning,
      feature_comment_cleaning,
      feature_recertification,
      feature_comment_recertification,
      feature_stripe_aligning,
      feature_comment_stripe_aligning,
      add_reserved_spaces,
      reserved_spaces,
      reserved_effective_date,
      reserved_expiration_date,
    } = modalData.data;

    const body = JSON.stringify({
      id,
      storage,
      anticipated_start_date,
      anticipated_end_date,
      loaded_empty,
      car_type,
      commodity,
      expected_no,
      hazmat,
      rail_car_id,
      ssds_doc,
      feature_hazmat,
      feature_comment_hazmat,
      feature_loaded_cars,
      feature_comment_loaded_cars,
      feature_cherry_picking,
      feature_comment_cherry_picking,
      feature_secured_facility,
      feature_comment_secured_facility,
      feature_repair,
      feature_comment_repair,
      feature_mechanical,
      feature_comment_mechanical,
      feature_cleaning,
      feature_comment_cleaning,
      feature_recertification,
      feature_comment_recertification,
      feature_stripe_aligning,
      feature_comment_stripe_aligning,
      add_reserved_spaces,
      reserved_spaces,
      reserved_effective_date,
      reserved_expiration_date,
      created_by: 3,
      rawData: modalData.data,
    });

    const url =
      modalData.data.id > 0 ? "/api/order/update" : "api/order/create";

    const response = fetch(url, {
      method: "POST",

      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },

      body: body,
    });

    closeModal();
  };

  const updateItem = (id, keyVal, newValue) => {
    // Find the index of the item to update
    console.log("NEEDS UPDATING: updateItem");
    const index = data.findIndex((item) => item.id === id);

    if (index === -1) {
      // Handle item not found
      return;
    }

    // Create a new array with the updated item
    const updatedArray = [...data];
    updatedArray[index] = { ...updatedArray[index], [keyVal]: newValue };

    // Set the state variable to the new array
    setData(updatedArray);
  };

  const tablecode = (
    <div className="container mt-4">
      <OrderViewModal
        show={modalData.modal}
        onClose={closeModal}
        storageID={modalData.id}
        formData={modalData.data}
        UpdateFormData={UpdateFormData}
        handleSubmit={handleSubmit}
      />

      <div className="page-header">
        <h1>Storage Order Requests</h1>
        <p className="lead">Manage and view your storage requests</p>
      </div>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            {columns.map((column) => (
              <th key={column.field} onClick={() => handleSort(column.field)}>
                {column.displayName}
                {sortedField === column.field && sortDirection === "asc" && (
                  <i className="bi bi-caret-up-fill"></i>
                )}
                {sortedField === column.field && sortDirection === "desc" && (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={column.field} onClick={() => handleRowClick(item.id)}>
                  {
                    parseJson(item, column.field)
                    //item[column.field]
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <FaChevronLeft />
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          className="btn btn-primary"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );

  return (
    <div className="home-page-container">
      <div className="home-page-container1">
        <HeaderBar />
        <div>{tablecode}</div>
        <div className="pt-5 mt-5">&nbsp;</div>
        <FooterBar />
      </div>
    </div>
  );
};

export default OrdersPage;
