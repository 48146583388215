import {
  useState,
  useEffect,
  componentDidMount,
  componentDidUpdate,
  useRef,
  useContext,
} from "react";
import "./App.css";
import { Button } from "react-bootstrap";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  GeoJSON,
  useMap,
} from "react-leaflet";

import L from "leaflet";
import HeaderBar from "./HeaderBar.jsx";
import FooterBar from "./FooterBar.jsx";
import { useDispatch, useSelector } from "react-redux";
import "./loader.js";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "leaflet/dist/leaflet.css";
import { Icon, divIcon, point } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import pako from "pako"; // Importing pako library
//import OrderModal from "./containers/OrderModal";
import rrimg from ".//rrimg.png";
import { RootState, AppDispatch } from "./store";
import { addStorageLocation, clearLocations } from "./features/storage";
import { OrderViewModal } from "./containers/OrderViewModal.jsx";
import "leaflet.vectorgrid";
import { GlobalContext } from "./GlobalContext";

function Dashboard(props) {
  const [geojsonData, setGeojsonData] = useState([]); // Initialize count to 0
  const [displayStorages, setDisplayStorages] = useState([]); // Initialize count to 0
  const [centerPosition, setCenterPosition] = useState([39.8283, -98.5795]); // Default center position [latitude, longitude
  const { globalVariable, setGlobalVariable } = useContext(GlobalContext);

  const mapRef = useRef();

  const vectorLayers = useRef([]);

  const defaultValues = {
    id: "",

    storage: "",
    anticipated_start_date: undefined,
    anticipated_end_date: undefined,
    loaded_empty: "Loaded",
    car_type: "Equipped Box Cars",
    commodity: "",
    expected_no: 0,
    hazmat: "No",
    rail_car_id: "",
    ssds_doc: undefined,
    feature_hazmat: false,
    feature_comment_hazmat: "",
    feature_loaded_cars: false,
    feature_comment_loaded_cars: "",
    feature_cherry_picking: false,
    feature_comment_cherry_picking: "",
    feature_secured_facility: false,
    feature_comment_secured_facility: "",
    feature_repair: false,
    feature_comment_repair: "",
    feature_mechanical: false,
    feature_comment_mechanical: "",
    feature_cleaning: false,
    feature_comment_cleaning: "",
    feature_recertification: false,
    feature_comment_recertification: "",
    feature_stripe_aligning: false,
    feature_comment_stripe_aligning: "",
    add_reserved_spaces: false,
    reserved_spaces: 0,
    reserved_effective_date: undefined,
    reserved_expiration_date: undefined,

    storName: "",
    storID: 0,
  };

  const [modalData, setModalData] = useState({
    modal: false,
    storageid: 0,
    data: defaultValues,
  });

  global["color_BNSF"] = "rgb(243, 127, 19)";
  global["color_UP"] = "rgb(13, 72, 219)";
  global["color_KCS"] = "rgb(118, 11, 11)";
  global["color_NS"] = "rgb(45, 165, 16)";
  global["color_CSXT"] = "rgb(226, 20, 20)";
  global["color_CPRS"] = "rgb(144, 6, 224)";
  global["color_CN"] = "rgb(235, 208, 4)";
  global["color_FXE"] = "rgb(202, 24, 166)";
  global["color_Local"] = "black";

  const appendArrayToGeojsonData = (itemsToAdd) => {
    setGeojsonData((prevGeojsonData) => [...prevGeojsonData, ...itemsToAdd]);
  };

  const dispatch = useDispatch();

  const obj = {
    propertyName: "value",
  };

  const UpdateFormData = (ud) => {
    console.log(ud);

    setModalData({
      ...modalData,
      data: { ...modalData.data, ...ud },
    });
  };

  const openModal = (storID, storName) => {
    console.log(storID, storName);
    setModalData((prevState) => ({
      ...prevState,
      modal: true,
      data: {
        ...modalData.data,
        storID: storID,
        storName: storName,
        storage: storID,
      },
    }));
  };

  const closeModal = () => {
    setModalData((prevState) => ({
      ...prevState,
      modal: false,
    }));
  };
  const generateKey = (pre, ty, state) => {
    return `${ty}_${pre}_${new Date().getTime()}`;
  };

  //  const { getLValue, setLValue } = useLocalStorage("geojsonData", "");

  const stateCenterPoints = [
    { abbreviation: "AL", latitude: 32.806671, longitude: -86.79113 }, // Alabama
    { abbreviation: "AK", latitude: 61.370716, longitude: -152.404419 }, // Alaska
    { abbreviation: "AZ", latitude: 33.729759, longitude: -111.431221 }, // Arizona
    { abbreviation: "AR", latitude: 34.969704, longitude: -92.373123 }, // Arkansas
    { abbreviation: "CA", latitude: 36.116203, longitude: -119.681564 }, // California
    { abbreviation: "CO", latitude: 39.059811, longitude: -105.311104 }, // Colorado
    { abbreviation: "CT", latitude: 41.597782, longitude: -72.755371 }, // Connecticut
    { abbreviation: "DE", latitude: 39.318523, longitude: -75.507141 }, // Delaware
    { abbreviation: "DC", latitude: 38.897438, longitude: -77.026817 }, // District of Columbia
    { abbreviation: "FL", latitude: 27.766279, longitude: -81.686783 }, // Florida
    { abbreviation: "GA", latitude: 33.040619, longitude: -83.643074 }, // Georgia
    { abbreviation: "HI", latitude: 21.094318, longitude: -157.498337 }, // Hawaii
    { abbreviation: "ID", latitude: 44.240459, longitude: -114.478828 }, // Idaho
    { abbreviation: "IL", latitude: 40.349457, longitude: -88.986137 }, // Illinois
    { abbreviation: "IN", latitude: 39.849426, longitude: -86.258278 }, // Indiana
    { abbreviation: "IA", latitude: 42.011539, longitude: -93.210526 }, // Iowa
    { abbreviation: "KS", latitude: 38.5266, longitude: -96.726486 }, // Kansas
    { abbreviation: "KY", latitude: 37.66814, longitude: -84.670067 }, // Kentucky
    { abbreviation: "LA", latitude: 31.169546, longitude: -91.867805 }, // Louisiana
    { abbreviation: "ME", latitude: 44.693947, longitude: -69.381927 }, // Maine
    { abbreviation: "MD", latitude: 39.063946, longitude: -76.802101 }, // Maryland
    { abbreviation: "MA", latitude: 42.230171, longitude: -71.530106 }, // Massachusetts
    { abbreviation: "MI", latitude: 43.326618, longitude: -84.536095 }, // Michigan
    { abbreviation: "MN", latitude: 45.694454, longitude: -93.900192 }, // Minnesota
    { abbreviation: "MS", latitude: 32.741646, longitude: -89.678696 }, // Mississippi
    { abbreviation: "MO", latitude: 38.456085, longitude: -92.288368 }, // Missouri
    { abbreviation: "MT", latitude: 46.921925, longitude: -110.454353 }, // Montana
    { abbreviation: "NE", latitude: 41.12537, longitude: -98.268082 }, // Nebraska
    { abbreviation: "NV", latitude: 38.313515, longitude: -117.055374 }, // Nevada
    { abbreviation: "NH", latitude: 43.452492, longitude: -71.563896 }, // New Hampshire
    { abbreviation: "NJ", latitude: 40.298904, longitude: -74.521011 }, // New Jersey
    { abbreviation: "NM", latitude: 34.840515, longitude: -106.248482 }, // New Mexico
    { abbreviation: "NY", latitude: 42.165726, longitude: -74.948051 }, // New York
    { abbreviation: "NC", latitude: 35.630066, longitude: -79.806419 }, // North Carolina
    { abbreviation: "ND", latitude: 47.528912, longitude: -99.784012 }, // North Dakota
    { abbreviation: "OH", latitude: 40.388783, longitude: -82.764915 }, // Ohio
    { abbreviation: "OK", latitude: 35.565342, longitude: -96.928917 }, // Oklahoma
    { abbreviation: "OR", latitude: 44.572021, longitude: -122.070938 }, // Oregon
    { abbreviation: "PA", latitude: 40.590752, longitude: -77.209755 }, // Pennsylvania
    { abbreviation: "RI", latitude: 41.680893, longitude: -71.51178 }, // Rhode Island
    { abbreviation: "SC", latitude: 33.856892, longitude: -80.945007 }, // South Carolina
    { abbreviation: "SD", latitude: 44.299782, longitude: -99.438828 }, // South Dakota
    { abbreviation: "TN", latitude: 35.747845, longitude: -86.692345 }, // Tennessee
    { abbreviation: "TX", latitude: 31.054487, longitude: -97.563461 }, // Texas
    { abbreviation: "UT", latitude: 40.150032, longitude: -111.862434 }, // Utah
    { abbreviation: "VT", latitude: 44.045876, longitude: -72.710686 }, // Vermont
    { abbreviation: "VA", latitude: 37.769337, longitude: -78.169968 }, // Virginia
    { abbreviation: "WA", latitude: 47.400902, longitude: -121.490494 }, // Washington
    { abbreviation: "WV", latitude: 38.491226, longitude: -80.954746 }, // West Virginia
    { abbreviation: "WI", latitude: 44.268543, longitude: -89.616508 }, // Wisconsin
    { abbreviation: "WY", latitude: 42.755966, longitude: -107.30249 }, // Wyoming
  ];

  function getLatLongByAbbreviation(abbreviation) {
    const state = stateCenterPoints.find(
      (state) => state.abbreviation === abbreviation.toUpperCase()
    );
    return state ? [state.latitude, state.longitude] : null;
  }

  function averageCoordinates(coordinates) {
    if (coordinates.length === 0) {
      return { latitude: 0, longitude: 0 }; // Return default values for empty array
    }

    // Initialize variables to store sum of latitudes and longitudes
    let sumLatitude = 0;
    let sumLongitude = 0;

    // Loop through each coordinate and accumulate the sum of latitudes and longitudes
    coordinates.forEach((coordinate) => {
      sumLatitude += coordinate.latitude;
      sumLongitude += coordinate.longitude;
    });

    // Calculate average latitude and longitude by dividing the sum by the total number of coordinates
    const avgLatitude = sumLatitude / coordinates.length;
    const avgLongitude = sumLongitude / coordinates.length;

    return { latitude: avgLatitude, longitude: avgLongitude };
  }

  const openDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("geojsonDB", 2);

      request.onerror = (event) => {
        console.error("Error opening database:", event.target.error);
        reject(event.target.error);
      };

      request.onsuccess = (event) => {
        const db = event.target.result;

        resolve(db);
      };

      request.onupgradeneeded = (event) => {
        console.log("Database upgrade needed...");
        const db = event.target.result;
        console.log("Creating object store...");
        const store = db.createObjectStore("geojsonStore", {
          keyPath: "state",
        });
        store.createIndex("state", "state", { unique: true });
      };
    });
  };

  const fetchAndStoreGeoJSON = async (state) => {
    getGeoJSONByState(state, async (responseData) => {
      if (responseData.length === 0) {
        console.log(`Start fetching and storing GeoJSON for ${state}...`);

        const staterrmap = [];
        const endpoints = [];
        var railroads = [
          "BNSF",
          "CN",
          "CPRS",
          "CSXT",
          "FXE",
          "KCS",
          "NS",
          "UP",
          "Local",
        ];
        for (const rr of railroads) {
          staterrmap.push({ state, rr });
        }

        staterrmap.forEach((loc) => {
          endpoints.push({
            url: `/data/${loc.state}_${loc.rr}_Trim.json`,
            color: global[`color_${loc.rr}`],
          });
        });

        try {
          try {
            const responses = await Promise.all(
              endpoints.map((endpoint) => fetch(endpoint.url))
            );
            const dataPromises = responses.map(async (response, index) => {
              const jsonData = await response.json();

              return {
                data: jsonData,
                color: endpoints[index].color,
                url: endpoints[index].url,
              };
            });

            const responseData = await Promise.all(dataPromises);

            const errors = responseData.filter((item) => item.error);

            if (errors.length > 0) {
              console.error("Some endpoints returned errors:", errors);
            } else {
              //   console.log("All data fetched successfully:", responseData);
            }

            const db = await openDB();

            const tx = db.transaction("geojsonStore", "readwrite");

            const store = tx.objectStore("geojsonStore");
            store.put({ state, data: responseData });

            await tx.complete;
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } catch (error) {
          console.error(
            `Error fetching and storing GeoJSON for ${state}:`,
            error
          );
        }
      }
    });
  };

  const createStyleFunction = (c) => {
    return (feature, layer) => {
      // Determine stroke width based on the current zoom level
      let strokeWidth = 2;

      // Return style object with dynamically determined stroke width
      return {
        weight: strokeWidth,
        fillColor: c,
        opacity: 1,
        color: c,
        dashArray: "0",
        fillOpacity: 0.7,
        smoothFactor: 0.5,
      };
    };
  };

  const getGeoJSONByState = async (state, then) => {
    return;
    try {
      console.log(`Get GeoJSON data for ${state}...`);
      const db = await openDB();
      const tx = db.transaction("geojsonStore", "readonly");
      const store = tx.objectStore("geojsonStore");
      const request = store.get(state);

      request.onsuccess = (event) => {
        console.log(`GeoJSON data for ${state} fetched successfully...`);

        const geojson = event.target.result;
        if (geojson) {
          then(geojson.data);
        } else {
          then([]);
        }
      };

      tx.onerror = (event) => {
        console.error(
          `Error fetching GeoJSON data for ${state}:`,
          event.target.error
        );
      };
    } catch (error) {
      console.error(`Error opening database or transaction:`, error);
    }
  };

  const tmpStorage = useSelector((state) => state.storage.storageLocations);

  const searchRail = () => {
    const mapx = mapRef.current;
    const lineWeight = L.Browser.retina ? 0.25 / 2 : 0.25;

    vectorLayers.current.forEach((layer) => {
      mapx.removeLayer(layer);
    });
    vectorLayers.current = [];

    for (let i = 0; i < selectedRROptions.length; i++) {
      const url =
        "https://storage.resolventtech.com/tileserver/data/" +
        selectedRROptions[i].value.toLowerCase() +
        "/{z}/{x}/{y}.pbf";

      var vectorGridLayer = L.vectorGrid
        .protobuf(url, {
          vectorTileLayerStyles: {
            bnsf: function (properties, zoom) {
              return {
                weight: 1, // Adjust weight for high DPI displays
                color: global["color_BNSF"],
                fillColor: global["color_BNSF"],
                fillOpacity: 0.5,
                lineCap: "round", // End of the lines
                lineJoin: "round",
              };
            },
            kcs: function (properties, zoom) {
              return {
                weight: 1, // Adjust weight for high DPI displays
                color: global["color_KCS"],
                fillColor: global["color_KCS"],
                fillOpacity: 0.5,
                lineCap: "round", // End of the lines
                lineJoin: "round",
              };
            },
            up: function (properties, zoom) {
              return {
                weight: 1, // Adjust weight for high DPI displays
                color: global["color_UP"],
                fillColor: global["color_UP"],
                fillOpacity: 0.5,
                lineCap: "round", // End of the lines
                lineJoin: "round",
              };
            },

            ns: function (properties, zoom) {
              return {
                weight: 1, // Adjust weight for high DPI displays
                color: global["color_NS"],
                fillColor: global["color_NS"],
                fillOpacity: 0.5,
                lineCap: "round", // End of the lines
                lineJoin: "round",
              };
            },

            csxt: function (properties, zoom) {
              return {
                weight: 1, // Adjust weight for high DPI displays
                color: global["color_CSXT"],
                fillColor: global["color_CSXT"],
                fillOpacity: 0.5,
                lineCap: "round", // End of the lines
                lineJoin: "round",
              };
            },

            cprs: function (properties, zoom) {
              return {
                weight: 1, // Adjust weight for high DPI displays
                color: global["color_CPRS"],
                fillColor: global["color_CPRS"],
                fillOpacity: 0.5,
                lineCap: "round", // End of the lines
                lineJoin: "round",
              };
            },

            cn: function (properties, zoom) {
              return {
                weight: 1, // Adjust weight for high DPI displays
                color: global["color_CN"],
                fillColor: global["color_CN"],
                fillOpacity: 0.5,
                lineCap: "round", // End of the lines
                lineJoin: "round",
              };
            },

            fxe: function (properties, zoom) {
              return {
                weight: 1, // Adjust weight for high DPI displays
                color: global["color_FXE"],
                fillColor: global["color_FXE"],
                fillOpacity: 0.5,
                lineCap: "round", // End of the lines
                lineJoin: "round",
              };
            },

            local: function (properties, zoom) {
              return {
                weight: 1, // Adjust weight for high DPI displays
                color: global["color_Local"],
                fillColor: global["color_Local"],
                fillOpacity: 0.5,
                lineCap: "round", // End of the lines
                lineJoin: "round",
              };
            },
          },
        })
        .on("tileerror", function (error) {
          console.error("Tile loading error:", error); // Log any tile loading errors
        })
        .addTo(mapx);

      vectorLayers.current.push(vectorGridLayer);
    }

    setDisplayStorages(tmpStorage);

    var states = [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ];

    states = selectedOptions;

    if (states.length == 0) return;

    const cp = getLatLongByAbbreviation(states[0].value);

    const map = mapRef.current;
    if (map) {
      //     map.setZoom(6);
      //      map.setView(cp);
      map.invalidateSize();
      map.flyTo(cp, 6);
    }

    console.log(states);

    states.forEach((state) => {
      getGeoJSONByState(state.value, (responseData) => {
        var gd = [];

        console.log(responseData);

        for (var indx = 0; indx < responseData.length; indx++) {
          const data = responseData[indx];
          const styleFunction = createStyleFunction(data.color);

          gd.push(
            <GeoJSON
              key={generateKey(indx, "geojson", state.value)}
              data={data.data}
              style={styleFunction}
            />
          );
        }

        console.log("done");

        appendArrayToGeojsonData(gd);
      });
    });
  };

  const getDataGeo = async (locs) => {
    const states = [
      "AL",
      "AK",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "FL",
      "GA",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "OH",
      "OK",
      "OR",
      "PA",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ];

    for (const state of states) {
      // fetchAndStoreGeoJSON(state);
    }
  };

  const handleSubmit = () => {
    console.log("Dashboard Model Data Submit");
    console.log(modalData.data);

    const {
      id,
      storage,
      anticipated_start_date,
      anticipated_end_date,
      loaded_empty,
      car_type,
      commodity,
      expected_no,
      hazmat,
      rail_car_id,
      ssds_doc,
      feature_hazmat,
      feature_comment_hazmat,
      feature_loaded_cars,
      feature_comment_loaded_cars,
      feature_cherry_picking,
      feature_comment_cherry_picking,
      feature_secured_facility,
      feature_comment_secured_facility,
      feature_repair,
      feature_comment_repair,
      feature_mechanical,
      feature_comment_mechanical,
      feature_cleaning,
      feature_comment_cleaning,
      feature_recertification,
      feature_comment_recertification,
      feature_stripe_aligning,
      feature_comment_stripe_aligning,
      add_reserved_spaces,
      reserved_spaces,
      reserved_effective_date,
      reserved_expiration_date,
    } = modalData.data;

    const body = JSON.stringify({
      id,
      storage,
      anticipated_start_date,
      anticipated_end_date,
      loaded_empty,
      car_type,
      commodity,
      expected_no,
      hazmat,
      rail_car_id,
      ssds_doc,
      feature_hazmat,
      feature_comment_hazmat,
      feature_loaded_cars,
      feature_comment_loaded_cars,
      feature_cherry_picking,
      feature_comment_cherry_picking,
      feature_secured_facility,
      feature_comment_secured_facility,
      feature_repair,
      feature_comment_repair,
      feature_mechanical,
      feature_comment_mechanical,
      feature_cleaning,
      feature_comment_cleaning,
      feature_recertification,
      feature_comment_recertification,
      feature_stripe_aligning,
      feature_comment_stripe_aligning,
      add_reserved_spaces,
      reserved_spaces,
      reserved_effective_date,
      reserved_expiration_date,
      rawData: modalData.data,
      created_by: globalVariable.user.id,
    });

    const url =
      modalData.data.id > 0 ? "/api/order/update" : "api/order/create";

    const response = fetch(url, {
      method: "POST",

      headers: {
        Accept: "application/json",

        "Content-Type": "application/json",
      },

      body: body,
    });

    closeModal();
  };

  useEffect(() => {
    dispatch(clearLocations());
    console.log("Use Effect");
    fetch("/api/storage/stor_list")
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        const ta = [];
        if (json.code == "token_not_valid") {
          console.log("Token not valid in dashboard storage");
          return;
        }

        console.log(json);
        if (json == undefined || json.length === 0) {
          return;
        }
        json.forEach((item) => {
          ta.push({
            geocode: [item.latitude, item.longitude],
            popUp: `Storage Location: ${item.facility_name}\n`,
            storId: item.id,
            storName: item.facility_name,
          });
        });

        dispatch(addStorageLocation(ta));
      });

    const startTime = performance.now();
    console.log("Use Effect");
    getDataGeo();
  }, []);

  /*
    fetch("/data/UP_TX.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        //        return response.arrayBuffer();
        return response.json();
      })
      .then((data) => {
        //        const inflatedData = pako.inflate(data, { to: "string" });

        //        data = JSON.parse(inflatedData);

        const gd = (
          <GeoJSON
            data={data}
            style={() => {
              {
                return {
                  fillColor: "red",
                  weight: 2,
                  opacity: 1,
                  color: "red",
                  dashArray: "3",
                  fillOpacity: 0.7,
                };
              }
            }}
          />
        );

        setGeojsonData(gd);

        const endTime = performance.now();
        console.log(
          `Component took ${endTime - startTime} milliseconds to render.`
        );
      })
      .catch((error) => {
        console.error("Error fetching or decompressing GeoJSON data: ", error);
      });
  }, []); // Empty dependency array ensures that this effect runs only once after the initial render
*/

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedRROptions, setSelectedRROptions] = useState([]);

  const customIcon = new Icon({
    // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
    iconUrl: require("./icons/placeholder.png"),
    iconSize: [25, 38], // size of the icon
  });

  const createClusterCustomIcon = function (cluster) {
    return new divIcon({
      html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
      className: "custom-marker-cluster",
      iconSize: point(33, 33, true),
    });
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };
  const handleRRChange = (selectedValues) => {
    setSelectedRROptions(selectedValues);
  };

  const optionRR = [
    { value: "BNSF", label: "BNSF" },
    { value: "UP", label: "UP" },
    { value: "KCS", label: "KCS" },
    { value: "NS", label: "NS" },
    { value: "CSXT", label: "CSXT" },
    { value: "CPRS", label: "CPRS" },
    { value: "CN", label: "CN" },
    { value: "FXE", label: "FXE" },
    { value: "Local", label: "Local" },
  ];

  const optionState = [
    { value: "AL", label: "Alabama" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DE", label: "Delaware" },
    { value: "DC", label: "District Of Columbia" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
  ];

  const animatedComponents = makeAnimated();
  // markers

  return (
    <div className="home-page-container">
      <div className="home-page-container1">
        <HeaderBar />
        <OrderViewModal
          show={modalData.modal}
          onClose={closeModal}
          storageID={modalData.id}
          formData={modalData.data}
          UpdateFormData={UpdateFormData}
          handleSubmit={handleSubmit}
        />

        <div className="ctr">
          <div className="left-column p-3">
            <div className="card">
              <header className="card-header">
                <h6 className="title">State</h6>
              </header>
              <Select
                className="p-2"
                options={optionState}
                isMulti
                onChange={handleSelectChange}
                value={selectedOptions}
                name="stateSelections"
                components={animatedComponents}
              />
            </div>
            <div className="card">
              <header className="card-header">
                <h6 className="title">Rail Roads</h6>
              </header>
              <Select
                className="p-2"
                options={optionRR}
                onChange={handleRRChange}
                isMulti
                name="railRoadSelections"
                components={animatedComponents}
              />
              <img src={rrimg} alt="RRMap" width={320} />
            </div>

            <div className="" style={{ paddingTop: "20px" }}>
              <button className="btn btn-primary show" onClick={searchRail}>
                Search
              </button>
            </div>
            <br />

            <br />
            <div className="">
              <h5 className="p-1 border-bottom">Storage Locations</h5>
              <ul id="item-list"></ul>
            </div>
          </div>

          <div className="right-column">
            <MapContainer
              center={centerPosition}
              zoom={6}
              maxZoom={10}
              minZoom={3}
              scrollWheelZoom={true}
              ref={mapRef}
            >
              <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {geojsonData}
              <MarkerClusterGroup
                chunkedLoading
                iconCreateFunction={createClusterCustomIcon}
              >
                {displayStorages.map((marker, indx) => {
                  return (
                    <Marker
                      key={generateKey(indx, "marker")}
                      position={marker.geocode}
                      icon={customIcon}
                    >
                      <Popup>
                        {marker.popUp}
                        <br />
                        <br />
                        <Button
                          variant="primary"
                          onClick={() =>
                            openModal(marker.storId, marker.storName)
                          }
                        >
                          Place Order
                        </Button>
                      </Popup>
                    </Marker>
                  );
                })}
              </MarkerClusterGroup>{" "}
            </MapContainer>
          </div>
        </div>
        <FooterBar />
      </div>
    </div>
  );
}

export default Dashboard;
