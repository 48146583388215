import { React } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { resetRegistered } from "../features/user";
import { useState, componentDidMount, componentDidUpdate } from "react";
import { Modal, Button, Form, ProgressBar } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FieldGroup from "./FieldGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const VendorViewModal = ({
  show,
  onClose,
  formData,
  UpdateFormData,
  handleSubmit,
}) => {
  const { register, hs, errors } = useForm();

  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChange = (e) => {
    console.log(e);
    const { id, value } = e.target;
    UpdateFormData({
      [id]: value,
    });
  };

  const handleNamedChange = (id, value) => {
    UpdateFormData({
      [id]: value,
    });
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {});
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const usStates = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  return (
    <Modal show={show} onHide={onClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Vendor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <>
            <div className="container">
              <FieldGroup label="Vendor Details">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="business_name" className="form-label">
                      Business Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="business_name"
                      value={formData.business_name}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="primary_contact" className="form-label">
                      Primary Contact
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="primary_contact"
                      value={formData.primary_contact}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="primary_email" className="form-label">
                      Primary Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="primary_email"
                      value={formData.primary_email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="secondary_contact" className="form-label">
                      Secondary Contact
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="secondary_contact"
                      value={formData.secondary_contact}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="secondary_email" className="form-label">
                      Secondary Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="secondary_email"
                      value={formData.secondary_email}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label">
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      value={formData.country}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="state" className="form-label">
                      State
                    </label>
                    <select
                      className="form-select"
                      id="state"
                      value={formData.state}
                      onChange={handleChange}
                    >
                      {usStates.map((state) => (
                        <option key={state}>{state}</option>
                      ))}
                    </select>
                  </div>{" "}
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">
                      City Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="mb-3">
                    <label htmlFor="zipcode" className="form-label">
                      Zip Code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="zipcode"
                      value={formData.zipcode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </FieldGroup>
              <FieldGroup label="Active Details">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="effective_date" className="form-label">
                      Effective Date
                    </label>
                    <div>
                      <DatePicker
                        expirationDate
                        dateFormat="MM/dd/yyyy"
                        className="form-control"
                        id="effectiveDate"
                        name="effectiveDate"
                        selected={formData.effective_date}
                        onChange={(date) =>
                          handleNamedChange("effective_date", date)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="expiration_date" className="form-label">
                      Expiration Date
                    </label>
                    <div>
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        className="form-control"
                        id="expiration_date"
                        name="expiration_date"
                        selected={formData.expirationDate}
                        onChange={(date) =>
                          handleNamedChange("expiration_date", date)
                        }
                      />
                    </div>
                  </div>
                </div>
              </FieldGroup>
              <FieldGroup label="Additional Information">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label htmlFor="website" className="form-label">
                        Website
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="website"
                        value={formData.website}
                        onChange={handleChange}
                      />
                    </div>
                  </div>{" "}
                </div>
                <div className="row">
                  <div style={{ clear: "both" }} className="col-md-10">
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        wrap="soft"
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>{" "}
                </div>
              </FieldGroup>
            </div>
          </>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          {formData.id > 0 ? "Update" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
