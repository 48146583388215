import React, { useState } from "react";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";

const InterchangeRailroadForm = ({ formData, setFormData }) => {
  //  const [items, setItems] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [laterror, setLatError] = useState("");
  const [longerror, setLongError] = useState("");

  const usStates = [
    "",
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const [eFormData, setEFormData] = useState({
    interchangeRailroadName: "",
    interchangeMark: "",
    interchangeGrossRailroadCapacity: "",
    interchangeUnit: "",
    location1Country: "",
    locationState: "",
    location1StationName: "",
    location1SPLC: "",
    location1Latitude: "",
    location1Longitude: "",
    location1R260: "",
    location1FSAC: "",
    location1Description: "",
    latitude: "",
    longitude: "",
  });

  const handleChange = (e) => {
    console.log("handlge change");
    const { id, value } = e.target;
    console.log(id, value);
    setEFormData({ ...eFormData, [id]: value });
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("text");
    const { id } = e.target;

    // Validate pasted data
    if (id === "latitude") {
      const latitude = parseFloat(pastedData);
      if (isNaN(latitude) || latitude < -90 || latitude > 90) {
        setLatError(
          "Invalid latitude value. Latitude must be between -90 and 90."
        );
        e.preventDefault(); // Prevent invalid data from being pasted
      } else {
        setLatError("");
      }
    }

    if (id === "longitude") {
      const latitude = parseFloat(pastedData);
      if (isNaN(latitude) || latitude < -90 || latitude > 90) {
        setLongError(
          "Invalid longitude value.  Longitude must be between -90 and 90."
        );
        e.preventDefault(); // Prevent invalid data from being pasted
      } else {
        setLongError("");
      }
    }
  };

  const handleAdd = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    if (editIndex !== null) {
      const updatedItems = formData.eItems.map((item, index) =>
        index === editIndex ? eFormData : item
      );
      setFormData({ ...formData, eItems: updatedItems });
      setEditIndex(null);
    } else {
      setFormData({ ...formData, eItems: [...formData.eItems, eFormData] });
    }

    setEFormData({
      ...eFormData,
      interchangeRailroadName: "",
      interchangeMark: "",
      interchangeGrossRailroadCapacity: "",
      interchangeUnit: "",
      location1Country: "",
      locationState: "",
      location1StationName: "",
      location1SPLC: "",
      location1Latitude: "",
      location1Longitude: "",
      location1R260: "",
      location1FSAC: "",
      location1Description: "",
      latitude: "",
      longitude: "",
    });
  };

  const handleEdit = (e, index) => {
    e.preventDefault(); // Prevents the default button behavior
    setEFormData(formData.eItems[index]);
    setEditIndex(index);
  };

  const handleDelete = (e, index) => {
    e.preventDefault(); // Prevents the default button behavior
    const updatedItems = formData.eItems.filter((_, i) => i !== index);

    setFormData({ ...formData, eItems: updatedItems });
  };

  const handleReset = () => {
    setFormData({
      interchangeRailroadName: "",
      interchangeMark: "",
      interchangeGrossRailroadCapacity: "",
      interchangeUnit: "",
      location1Country: "",
      locationState: "",
      location1StationName: "",
      location1SPLC: "",
      location1Latitude: "",
      location1Longitude: "",
      location1R260: "",
      location1FSAC: "",
      location1Description: "",
      latitude: "",
      longitude: "",
    });
    setEditIndex(null);
  };

  return (
    <div className="field-group-form">
      <div className="row">
        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="interchangeRailroadName" className="form-label">
              Interchange Railroad Name (SCAC)
            </label>
            <input
              type="text"
              className="form-control"
              id="interchangeRailroadName"
              value={eFormData.interchangeRailroadName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-3">
            <label htmlFor="interchangeMark" className="form-label">
              Mark
            </label>
            <input
              type="text"
              className="form-control"
              id="interchangeMark"
              value={eFormData.interchangeMark}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-3">
            <label
              htmlFor="interchangeGrossRailroadCapacity"
              className="form-label"
            >
              Gross Railroad Capacity (lbs)
            </label>
            <input
              type="Number"
              className="form-control"
              id="interchangeGrossRailroadCapacity"
              value={eFormData.interchangeGrossRailroadCapacity}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="location1Country" className="form-label">
              Country
            </label>
            <input
              type="text"
              className="form-control"
              id="location1Country"
              value={eFormData.location1Country}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="locationState" className="form-label">
              State
            </label>
            <select
              className="form-select"
              id="locationState"
              value={eFormData.locationState}
              onChange={handleChange}
            >
              {usStates.map((state) => (
                <option key={state}>{state}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="location1StationName" className="form-label">
              Station Name
            </label>
            <input
              type="text"
              className="form-control"
              id="location1StationName"
              value={eFormData.location1StationName}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="location1SPLC" className="form-label">
              SPLC
            </label>
            <input
              type="text"
              className="form-control"
              id="location1SPLC"
              value={eFormData.location1SPLC}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="latitude" className="form-label">
              Latitude
            </label>
            <input
              type="number"
              step="any"
              className={`form-control ${laterror ? "is-invalid" : ""}`}
              id="latitude"
              value={eFormData.latitude1}
              onChange={handleChange}
              onPaste={handlePaste}
            />
            {laterror && <div className="invalid-feedback">{laterror}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="longitude" className="form-label">
              Longitude
            </label>
            <input
              type="number"
              step="any"
              className={`form-control ${longerror ? "is-invalid" : ""}`}
              id="longitude"
              value={eFormData.longitude1}
              onChange={handleChange}
              onPaste={handlePaste}
            />
            {longerror && <div className="invalid-feedback">{longerror}</div>}
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="location1R260" className="form-label">
              R260
            </label>
            <input
              type="text"
              className="form-control"
              id="location1R260"
              value={eFormData.location1R260}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="location1FSAC" className="form-label">
              FSAC
            </label>
            <input
              type="text"
              className="form-control"
              id="location1FSAC"
              value={eFormData.location1FSAC}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-md-8">
          <div className="mb-3">
            <label htmlFor="location1Description" className="form-label">
              Description
            </label>
            <input
              type="text"
              className="form-control"
              id="location1Description"
              value={eFormData.location1Description}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="col-md-4 d-flex align-items-end">
          <div className="mb-3 w-100 d-flex justify-content-between align-items-end">
            <div className="d-flex align-items-center ms-2">
              <button
                className="btn btn-primary"
                style={{ whiteSpace: "nowrap" }}
                onClick={handleAdd}
              >
                {editIndex !== null ? "Update" : "Add"} <FaPlus />
              </button>
              {editIndex !== null && (
                <button
                  className="btn btn-secondary ms-2"
                  onClick={handleReset}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className="list-group mt-3">
        {formData.eItems.map((item, index) => (
          <li
            key={index}
            className="list-group-item d-flex justify-content-between align-items-center"
          >
            <div>
              <strong>{item.interchangeRailroadName}</strong> -{" "}
              {item.interchangeMark} - {item.interchangeGrossRailroadCapacity}
            </div>
            <div>
              <button
                className="btn btn-sm btn-warning mx-1"
                onClick={(e) => handleEdit(e, index)}
              >
                <FaEdit />
              </button>
              <button
                className="btn btn-sm btn-danger mx-1"
                onClick={(e) => handleDelete(e, index)}
              >
                <FaTrash />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InterchangeRailroadForm;
