import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { resetRegistered } from "../features/user";
import { useState, componentDidMount, componentDidUpdate } from "react";
import {
  Modal,
  Button,
  Form,
  ProgressBar,
  Row,
  Container,
  Col,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import FieldGroup from "./FieldGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  faArrowUpFromGroundWater,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalContext } from "../GlobalContext";

export const OrderViewModal = ({
  show,
  onClose,
  formData,
  UpdateFormData,
  handleSubmit,
}) => {
  const { register, hs, errors } = useForm();
  const { globalVariable, setGlobalVariable } = useContext(GlobalContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const indentStyle = { paddingLeft: "1.5rem" };

  const dispatch = useDispatch();
  useEffect(() => {});
  const handleNext = () => {
    setCurrentPage(
      currentPage + (currentPage === 2 && formData.id === "" ? 2 : 1)
    );
  };

  const handlePrevious = () => {
    setCurrentPage(
      currentPage - (currentPage === 4 && formData.id === "" ? 2 : 1)
    );
  };

  const handleDateChange = (id, date) => {
    UpdateFormData({ [id]: date });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    UpdateFormData({
      [id]: value,
    });
  };

  const handleNamedChange = (id, value) => {
    UpdateFormData({
      [id]: value,
    });
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const onSubmit = (data) => {
    console.log(data);
  };
  console.log(globalVariable);

  return (
    <Modal show={show} onHide={onClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Storage Order - {formData.storName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="progress-wrapper">
          <div className="wiznav">
            <span
              key="step-1"
              onClick={() => setCurrentPage(1)}
              className={"wizdot " + (currentPage == 1 ? "wizactive" : "")}
            >
              &bull;
            </span>
            <span
              key="step-2"
              onClick={() => setCurrentPage(2)}
              className={"wizdot " + (currentPage == 2 ? "wizactive" : "")}
            >
              &bull;
            </span>

            {formData.id != "" ? (
              <span
                key="step-3"
                onClick={() => setCurrentPage(3)}
                className={"wizdot " + (currentPage == 3 ? "wizactive" : "")}
              >
                {" "}
                &bull;
              </span>
            ) : (
              <></>
            )}

            <span
              key="step-4"
              onClick={() => setCurrentPage(4)}
              className={"wizdot " + (currentPage == 4 ? "wizactive" : "")}
            >
              &bull;
            </span>
          </div>
        </div>

        <Form>
          {
            //           <div className="col-12">
            //              <button type="submit" className="btn btn-primary">
            //                Submit
            //              </button>
            //            </div>
          }
          {currentPage === 1 && (
            <>
              <div className="container">
                {/* Loaded/Empty dropdown */}
                <FieldGroup label="Anticipated Dates">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="startDate" className="form-label">
                        Start Date
                      </label>
                      <div>
                        <DatePicker
                          selected={formData.startDate}
                          onChange={(date) =>
                            handleDateChange("startDate", date)
                          }
                          dateFormat="MM/dd/yyyy"
                          className="form-control"
                          id="startDate"
                          name="startDate"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="endDate" className="form-label">
                        End Date
                      </label>
                      <div>
                        <DatePicker
                          selected={formData.endDate}
                          onChange={(date) => handleDateChange("endDate", date)}
                          dateFormat="MM/dd/yyyy"
                          className="form-control"
                          id="endDate"
                          name="endDate"
                        />
                      </div>
                    </div>
                  </div>
                </FieldGroup>

                <FieldGroup label="Railcar Details">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="loadedEmpty" className="form-label">
                        Loaded/Empty
                      </label>
                      <select
                        className="form-select"
                        id="loadedEmpty"
                        onChange={handleChange}
                        name="loadedEmpty"
                        // You may need to handle onChange event to capture the selected value
                      >
                        <option value="Loaded">Loaded</option>
                        <option value="Empty">Empty</option>
                        <option value="EmptyResidue">Empty Residue</option>
                      </select>
                    </div>
                  </div>
                  {/* Commodity text field */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="commodity" className="form-label">
                        Commodity
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="commodity"
                        onChange={handleChange}
                        value={formData.commodity}
                        name="commodity"
                      />
                    </div>
                  </div>
                  {/* Hazmat dropdown */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="hazmat" className="form-label">
                        Hazmat
                      </label>
                      <select
                        className="form-select"
                        onChange={handleChange}
                        id="hazmat"
                        name="hazmat"
                        // You may need to handle onChange event to capture the selected value
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  {/* Car Type dropdown */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="carType" className="form-label">
                        Car Type
                      </label>
                      <select
                        className="form-select"
                        id="carType"
                        onChange={handleChange}
                        name="carType"
                        // You may need to handle onChange event to capture the selected value
                      >
                        <option value="ListOfCarTypes">
                          List Of Car Types
                        </option>
                        <option value="Equipped Box Cars">
                          Equipped Box Cars
                        </option>
                        <option value="UnEquipped Box Cars">
                          UnEquipped Box Cars
                        </option>
                        <option value="Covered Hopper Cars">
                          Covered Hopper Cars
                        </option>
                        <option value="Locomotive">Locomotive</option>
                        <option value="Equipped Gondola">
                          Equipped Gondola
                        </option>
                        <option value="Refrigerator Cars">
                          Refrigerator Cars
                        </option>
                        <option value="Stack Car">Stack Car</option>
                        <option value="Tank Cars">Tank Cars</option>
                        <option value="Container Cars">Container Cars</option>
                        <option value="Vehicular Flat Cars">
                          Vehicular Flat Cars
                        </option>
                        <option value="Trailers">Tailers</option>
                        <option value="Flat Cars">Flat Cars</option>
                        <option value="UnEquipped Hopper Cars">
                          UnEquipped Hopper Cars
                        </option>
                        <option value="UnEquipped Gondola Cars">
                          UnEquipped Gondola Cars
                        </option>
                        <option value="Gondola Cars">Gondola Cars</option>
                        <option value="Equipped Hopper Cars">
                          Equipped Hopper Cars
                        </option>
                        <option value="Special Type Cars">
                          Special Type Cars
                        </option>
                        <option value="Conventional Intermodal Cars">
                          Conventional Intermodal Cars
                        </option>
                      </select>
                    </div>
                  </div>
                  {/* Expected No Cars text field */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="expectedNoCars" className="form-label">
                        Expected No Cars
                      </label>
                      <input
                        type="text"
                        onChange={handleChange}
                        className="form-control"
                        id="expectedNoCars"
                        name="expectedNoCars"
                      />
                    </div>
                  </div>
                  {/* Railcar ID text area */}
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="railcarId" className="form-label">
                        Railcar ID
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          data-tooltip-id="caiidtip"
                          data-tooltip-html="Enter the comma separated <br/>list of car IDs."
                          className="info-icon"
                        />
                      </label>
                      <ReactTooltip id="caiidtip" />
                      <textarea
                        className="form-control"
                        id="railcarId"
                        onChange={handleChange}
                        name="railcarId"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                </FieldGroup>
                <FieldGroup label="Upload SSDS">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="fileUpload"
                        name="fileUpload"
                        onChange={handleFileChange}
                      />
                    </div>
                    {selectedFile && (
                      <div>
                        <p>Selected file: {selectedFile.name}</p>
                      </div>
                    )}
                  </div>
                </FieldGroup>
              </div>
            </>
          )}
          {currentPage === 2 && (
            <>
              <div className="container">
                {/* Loaded/Empty dropdown */}

                <FieldGroup label="Car Features">
                  <div className="col-md-4 align-items-center">
                    <div className="mb-3 form-check align-items-center">
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck"
                      >
                        Feature
                      </label>
                    </div>
                  </div>
                  <div className="col-md-8 align-items-center">
                    <div className="mb-3 form-check align-items-center">
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck"
                      >
                        Comment
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 align-items-center">
                    <div className="mb-3 form-check align-items-center">
                      <input
                        type="checkbox"
                        onChange={handleChange}
                        className="form-check-input"
                        id="exampleCheck"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck"
                      >
                        Hazmat Cars
                      </label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="mb-3">
                      <input
                        type="text"
                        onChange={handleChange}
                        className="form-control"
                        id="textBox"
                      />
                    </div>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <div className="mb-3 form-check align-items-center">
                      <input
                        type="checkbox"
                        onChange={handleChange}
                        className="form-check-input"
                        id="exampleCheck"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck"
                      >
                        Loaded Cars
                      </label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="mb-3">
                      <input
                        type="text"
                        onChange={handleChange}
                        className="form-control"
                        id="textBox"
                      />
                    </div>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <div className="mb-3 form-check align-items-center">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={handleChange}
                        id="exampleCheck"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck"
                      >
                        Cherry Picking
                      </label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        id="textBox"
                      />
                    </div>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <div className="mb-3 form-check align-items-center">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck"
                      >
                        Secured Facility
                      </label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="mb-3">
                      <input
                        type="text"
                        onChange={handleChange}
                        className="form-control"
                        id="textBox"
                      />
                    </div>
                  </div>

                  <div className="col-md-4 align-items-center">
                    <div className="mb-3 form-check align-items-center">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="exampleCheck"
                      >
                        Repairs
                      </label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="textBox"
                      />
                    </div>
                  </div>
                </FieldGroup>

                <FieldGroup label="Reserved Spaces">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="reservedspaces" className="form-label">
                        Number Spaces
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        id="reservedspaces"
                        name="reservedspaces"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="effectiveDate" className="form-label">
                        Effective Date
                      </label>
                      <div>
                        <DatePicker
                          selected={formData.effectiveDate}
                          onChange={(date) =>
                            handleDateChange("effectiveDate", date)
                          }
                          dateFormat="MM/dd/yyyy"
                          className="form-control"
                          id="effectiveDate"
                          name="effectiveDate"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="expirationDate" className="form-label">
                        Expiration Date
                      </label>
                      <div>
                        <DatePicker
                          selected={formData.expirationDate}
                          onChange={(date) =>
                            handleDateChange("expirationDate", date)
                          }
                          dateFormat="MM/dd/yyyy"
                          className="form-control"
                          id="expirationDate"
                          name="expirationDate"
                        />
                      </div>
                    </div>
                  </div>
                </FieldGroup>
              </div>
            </>
          )}
          {currentPage === 3 && (
            <>
              <Container>
                <h4>Rates</h4>
                <Row>
                  <Col md={3}></Col>
                  <Col md={3}>
                    <Form.Label>Daily Rate</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Label>Switch Fee (In)</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Label>Switch Fee (Out)</Form.Label>
                  </Col>
                </Row>

                <b className="mt-4">Cleaned Cars</b>
                <Row>
                  <Col md={3}>
                    <Form.Label>Empty (only)</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.emptyDailyRate}
                      onChange={handleChange}
                      id="emptyDailyRate"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.emptySwitchFeeIn}
                      onChange={handleChange}
                      id="emptySwitchFeeIn"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.emptySwitchFeeOut}
                      onChange={handleChange}
                      id="emptySwitchFeeOut"
                    />
                  </Col>
                </Row>

                <b className="mt-4">Non-Placarded Rates</b>
                <Row>
                  <Col md={3}>
                    <Form.Label>Loaded</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.loadedDailyRate}
                      onChange={handleChange}
                      id="loadedDailyRate"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.loadedSwitchFeeIn}
                      onChange={handleChange}
                      id="loadedSwitchFeeIn"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.loadedSwitchFeeOut}
                      onChange={handleChange}
                      id="loadedSwitchFeeOut"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={3}>
                    <Form.Label>Empty</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.nonPlacardedEmptyDailyRate}
                      onChange={handleChange}
                      id="nonPlacardedEmptyDailyRate"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.nonPlacardedEmptySwitchFeeIn}
                      onChange={handleChange}
                      id="nonPlacardedEmptySwitchFeeIn"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.nonPlacardedEmptySwitchFeeOut}
                      onChange={handleChange}
                      id="nonPlacardedEmptySwitchFeeOut"
                    />
                  </Col>
                </Row>

                <b className="mt-4">Placarded Cars</b>
                <Row>
                  <Col md={3}>
                    <Form.Label>Loaded</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedLoadedDailyRate}
                      onChange={handleChange}
                      id="placardedLoadedDailyRate"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedLoadedSwitchFeeIn}
                      onChange={handleChange}
                      id="placardedLoadedSwitchFeeIn"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedLoadedSwitchFeeOut}
                      onChange={handleChange}
                      id="placardedLoadedSwitchFeeOut"
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={3}>
                    <Form.Label>Empty</Form.Label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedEmptyDailyRate}
                      onChange={handleChange}
                      id="placardedEmptyDailyRate"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedEmptySwitchFeeIn}
                      onChange={handleChange}
                      id="placardedEmptySwitchFeeIn"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.placardedEmptySwitchFeeOut}
                      onChange={handleChange}
                      id="placardedEmptySwitchFeeOut"
                    />
                  </Col>
                </Row>

                <b className="mt-4">Other Rates or Fees</b>
                <Row>
                  <Col md={3}>
                    <Form.Label>Intra-Yard Switch Fee</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.intraYardSwitchFee}
                      onChange={handleChange}
                      id="intraYardSwitchFee"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Label>Cherry Picking Fee</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.cherryPickingFee}
                      onChange={handleChange}
                      id="cherryPickingFee"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Label>Non-Consent Fee</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.nonConsentFee}
                      onChange={handleChange}
                      id="nonConsentFee"
                    />
                  </Col>
                  <Col md={3}>
                    <Form.Label>Holdover Fee</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.holdoverFee}
                      onChange={handleChange}
                      id="holdoverFee"
                    />
                  </Col>
                </Row>

                <b className="mt-4">Access Fee (per occurrence)</b>
                <Row>
                  <Col md={6}>
                    <Form.Label>Cleaning or Maintenance</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.cleaningMaintenanceFee}
                      onChange={handleChange}
                      id="cleaningMaintenanceFee"
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Label>Inspections</Form.Label>
                    <Form.Control
                      type="number"
                      className="form-control"
                      value={formData.inspectionFee}
                      onChange={handleChange}
                      id="inspectionFee"
                    />
                  </Col>
                </Row>
              </Container>
            </>
          )}

          {currentPage === 4 && (
            <>
              <div className="container">
                {formData.id != "" ? (
                  <FieldGroup label="Proposal Notes">
                    <div style={{ width: "100%" }}>
                      <div className="mb-3">
                        <textarea
                          id="proposalNotes"
                          value={formData.proposalNotes}
                          onChange={handleChange}
                          rows="4" // Adjust the number of rows to change the height of the textarea
                          // Adjust the number of columns to change the width of the textarea
                          style={{ width: "100%", boxSizing: "border-box" }}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </FieldGroup>
                ) : (
                  <> </>
                )}
                <FieldGroup label="Order Summary">
                  <div style={{ height: "430px" }}>
                    <Container>
                      <h5>Anticipated Dates</h5>
                      <div style={indentStyle}>
                        <Row>
                          <Col md={6}>
                            <p>
                              <strong>Start Date:</strong>{" "}
                              {formData.startDate
                                ? formData.startDate.toLocaleDateString(
                                    "en-US",
                                    options
                                  )
                                : ""}
                            </p>
                          </Col>
                          <Col md={6}>
                            <p>
                              <strong>End Date:</strong>{" "}
                              {formData.endDate
                                ? formData.endDate.toLocaleDateString(
                                    "en-US",
                                    options
                                  )
                                : ""}
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <h5>Railcar Details</h5>
                      <div style={indentStyle}>
                        <Row>
                          <Col md={4}>
                            <p>
                              <strong>Loaded/Empty:</strong>{" "}
                              {formData.loadedEmpty}
                            </p>
                          </Col>
                          <Col md={4}>
                            <p>
                              <strong>Commodity:</strong> {formData.commodity}
                            </p>
                          </Col>
                          <Col md={4}>
                            <p>
                              <strong>Hazmat:</strong> {formData.hazmat}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <p>
                              <strong>Car Type:</strong> {formData.carType}
                            </p>
                          </Col>
                          <Col md={4}>
                            <p>
                              <strong>Expected No Cars:</strong>{" "}
                              {formData.expectedNoCars}
                            </p>
                          </Col>
                          <Col md={4}>
                            <p>
                              <strong>Railcar ID:</strong> {formData.railcarId}
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <h5>Car Features</h5>
                      <div style={indentStyle}>
                        <Row>
                          <Col md={6}>
                            <p>
                              <strong>Hazmat Cars:</strong>{" "}
                              {formData.exampleCheck ? "Yes" : "No"}
                            </p>
                            <p>
                              <strong>Loaded Cars:</strong>{" "}
                              {formData.exampleCheck ? "Yes" : "No"}
                            </p>
                            <p>
                              <strong>Cherry Picking:</strong>{" "}
                              {formData.exampleCheck ? "Yes" : "No"}
                            </p>
                          </Col>
                          <Col md={6}>
                            <p>
                              <strong>Secured Facility:</strong>{" "}
                              {formData.exampleCheck ? "Yes" : "No"}
                            </p>
                            <p>
                              <strong>Repairs:</strong>{" "}
                              {formData.exampleCheck ? "Yes" : "No"}
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <h5>Reserved Spaces</h5>
                      <div style={indentStyle}>
                        <Row>
                          <Col md={4}>
                            <p>
                              <strong>Number Spaces:</strong>{" "}
                              {formData.reservedspaces}
                            </p>
                          </Col>
                          <Col md={4}>
                            <p>
                              <strong>Effective Date:</strong>{" "}
                              {formData.effectiveDate
                                ? formData.effectiveDate.toLocaleDateString(
                                    "en-US",
                                    options
                                  )
                                : ""}
                            </p>
                          </Col>
                          <Col md={4}>
                            <p>
                              <strong>Expiration Date:</strong>{" "}
                              {formData.expirationDate
                                ? formData.expirationDate.toLocaleDateString(
                                    "en-US",
                                    options
                                  )
                                : ""}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Container>
                  </div>
                </FieldGroup>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {currentPage > 1 && (
          <Button variant="secondary" onClick={handlePrevious}>
            Previous
          </Button>
        )}
        {currentPage < 4 && (
          <Button variant="primary" onClick={handleNext}>
            Next
          </Button>
        )}
        {currentPage === 4 && (
          <>
            <Button variant="warning" onClick={handleSubmit}>
              {formData.id > 0 ? "Reject" : "Add Request"}
            </Button>
            {formData.id > 0 ? (
              <Button variant="primary" onClick={handleSubmit}>
                Accept
              </Button>
            ) : (
              <></>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
