import React, { useEffect, useContext } from "react";
import HeaderBar from "../HeaderBar";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { useState } from "react";
import { register, resetRegistered, login, getUser } from "../features/user";
import FooterBar from "../FooterBar";

import { GlobalContext } from "../GlobalContext.js";

const LoginPage = () => {
  const dispatch = useDispatch();
  const { globalVariable, setGlobalVariable } = useContext(GlobalContext);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { loading, isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if (register) {
      dispatch(resetRegistered());
    }
  }, [register]);

  const { email, passwrod } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(login(formData));
  };

  const handleFetchData = async () => {
    const resultAction = await dispatch(getUser());

    setGlobalVariable((prev) => ({ ...prev, user: resultAction.payload }));
  };

  if (isAuthenticated) {
    handleFetchData();

    return <Navigate to="/" />;
  }

  return (
    <div className="home-page-container">
      <div className="home-page-container1">
        <HeaderBar />
        <div>
          <div>
            <h1>Log into your Account</h1>
          </div>

          <form className="mt-5" onSubmit={onSubmit}>
            <div className="form-group">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>

            <div className="form-group mt-3">
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <input
                className="form-control"
                type="password"
                name="password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
            </div>
            {loading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <button className="btn btn-primary mt-4" type="submit">
                Login
              </button>
            )}
          </form>
        </div>
        <div className="pt-5 mt-5">&nbsp;</div>
        <FooterBar />
      </div>
    </div>
  );
};

export default LoginPage;
