import React from "react";

const FieldGroup = ({ label, children }) => {
  return (
    <div className="border rounded mb-3 row align-items-center">
      <label className="form-label">{label}</label>
      {children}
    </div>
  );
};

export default FieldGroup;
