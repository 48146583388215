import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  storageLocations: [],
};

const storageLocationsSlice = createSlice({
  name: "storageLocations",
  initialState,
  reducers: {
    addStorageLocation(state, action) {
      state.storageLocations.push(...action.payload);
    },
    clearLocations(state, action) {
      state.storageLocations = [];
    },
  },
});

export const { addStorageLocation, clearLocations } =
  storageLocationsSlice.actions;
export default storageLocationsSlice.reducer;
